import publicApi from "@api/publicApi";
import { decideStartPage } from "@lib/decideStartPage";
import { CircularProgress } from "@mui/material";
import { credentialsState, demoState, sessState, userState } from "@recoil/atoms";
import { setChatStateValues } from "@services/Auth";
import log from "@services/Log";
import User from "@services/User";
import { Onboarding } from "@templates/Onboarding";
import { config } from "@utils/config";
import { AxiosResponse } from "axios";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { getRecoil, setRecoil } from "recoil-nexus";

type PageProps = {
  token: string;
};
export default function IndexPage({ token }: PageProps) {
  const router = useRouter();
  const isInDemo = getRecoil(demoState);

  useEffect(() => {
    if (isInDemo) return;
    if (token) {
      publicApi
        .post(`${config.NEXT_PUBLIC_AUTH_API}/auth/login`, {
          authToken: token,
        })
        .then((res: AxiosResponse<any, any>) => {
          const { session, access } = res.data;
          setRecoil(sessState, session);
          // set access token
          setRecoil(credentialsState, access);
          return User.getAuthorizedUser();
        })
        .then((user) => {
          setRecoil(userState, user);
          setChatStateValues(user);

          const path = decideStartPage(user, false);
          if (path) router.push(path);
        })
        .catch((e: any) => {
          log.error(e, e?.message);
          // go to signin page, credential fetching failed
          router.push("/signin", {
            query: {
              error: "There was a problem logging you in.",
            },
          });
        });
    } else {
      publicApi
        .get("/auth/credentials", {
          headers: {
            Authorization: `Bearer ${getRecoil(sessState)}`,
          },
        })
        .then((res: AxiosResponse<any, any>) => {
          setRecoil(credentialsState, res.data);
          return User.getAuthorizedUser();
        })
        .then((user) => {
          setRecoil(userState, user);
          setChatStateValues(user);

          const path = decideStartPage(user, false);
          if (path) router.push(path);
        })
        .catch((e) => {
          // go to signin page, credential fetching failed
          return router.push("/signin");
        });
    }
  }, []);

  return (
    <Onboarding shouldCenterAlignBody>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size={22}
          style={{ color: "#222" }}
        />
      </div>
    </Onboarding>
  );
}

IndexPage.getInitialProps = async ({ query }: { query: PageProps }) => {
  const { token } = query;
  return { token };
};
